<template>
    <div>
        <div class="header-title">{{$t("Swap")}}</div>

        <div class="swap-body">
            <div class="swap-container">
                <div class="swap-container-coin" v-for="selects in coin_selected " v-bind:key="selects.coinID">
                    <div class="coin-img">
                        <img :src="require('../assets/images/bitcoin.png')" >
                    </div>
                    <div>
                        <b-button class="select-coin-btn" @click="showCurrentCoinModal">
                            <div class="d-flex flex-row">
                                <span>{{ selects.coinCode }}</span>
                                <span><i class="fa fa-caret-down ml-3" aria-hidden="true"></i></span>
                            </div>
                        </b-button>
                    </div>
                    <div class="max-input">
                        <input type="number" class="coin-input"/>
                        <span class="max-text">{{$t("MAX")}}</span>
                    </div>
                    <span class="available-coin">
                        {{$t("Available")}}: 0 {{ selects.coinCode }}
                    </span>
                </div>
                <div class="swap-icon">
                    <img :src="require('../assets/images/swap_icon.png')" width="80">  
                </div>
                <div class="swap-container-coin" >
                    <div class="coin-img">
                        <img :src="require('../assets/images/bitcoin.png')" >
                    </div>
                    <div>
                        <b-button class="select-coin-btn" @click="showSwapCoinModal">
                            <div class="d-flex flex-row">
                                <span v-for="swapCoin in swap_coin_selected" v-bind:key="swapCoin.coinID"> {{ swapCoin.coinCode }}</span>
                                <span><i class="fa fa-caret-down ml-3" aria-hidden="true"></i></span>
                            </div>
                        </b-button>
                    </div>
                    <input type="number" class="coin-input"/>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <button class="swap-btn">{{$t("Swap")}}</button>
            </div>
            <div class="d-flex justify-content-center">
                <div class="swap-coin-info"> 
                    <table class="w-100">
                        <tbody v-for="swapCoin in swap_coin_selected" v-bind:key="swapCoin.coinID">
                            <tr>
                                <td class="info-left-col">{{$t("Minimum Received")}}</td>
                                <td class="info-right-col">0.00 {{ swapCoin.coinCode }}</td>
                            </tr>

                            <tr>
                                <td class="info-left-col">{{$t("Price Impact")}}</td>
                                <td class="info-right-col"> >0.00%</td>
                            </tr>

                            <tr>
                                <td class="info-left-col">{{$t("Liquidity Provider Fee")}}</td>
                                <td class="info-right-col">0.000 {{ swapCoin.coinCode }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- Current Coin Modal -->
            <b-modal ref="currentCoinModal" hide-footer title="SEARCH A TOKEN" centered>
                <div class="d-flex flex-column justify-content-center align-items-center">
                    <div class="search-token-input">
                        <input type="text" :placeholder="$t(`Search By Token Name`)" v-model="searchToken"/>
                        <span class="search-token-icon">
                            <img :src="require('../assets/icon/search.svg')" >
                        </span>
                    </div>
                    <div class="token-list">
                        <div v-for="coin in filteredCurrentList" v-bind:key="coin.coinID" @click="selected=coin.coinID; closeModal();" >
                            <div class="d-flex flex-row search-token-div" id="searchTokenContainer">
                                <div class="mr-4">
                                    <img :src="require('../assets/images/' + coin.imageName + '')" width=50 height=50/>
                                </div>
                                <div class="d-flex flex-column justify-content-center coin">
                                    <div><strong>{{ coin.coinName }}</strong></div>
                                    <div class="text-light-gray small">
                                        {{ coin.coinCode }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>

            <!-- Swap Coin Modal -->
            <b-modal ref="swapCoinModal" hide-footer title="SEARCH A TOKEN" centered>
                <div class="d-flex flex-column justify-content-center align-items-center">
                    <div class="search-token-input">
                        <input type="text" :placeholder="$t(`Search By Token Name`)" v-model="searchToken"/>
                        <span class="search-token-icon">
                            <img :src="require('../assets/icon/search.svg')" >
                        </span>
                    </div>
                    <div class="token-list">
                        <div v-for="coin in filteredSwapList" v-bind:key="coin.coinID" @click="swap_selected=coin.coinID; closeModal();" >
                            <div class="d-flex flex-row search-token-div" id="searchTokenContainer">
                                <div class="mr-4">
                                    <img :src="require('../assets/images/' + coin.imageName + '')" width=50 height=50/>
                                </div>
                                <div class="d-flex flex-column justify-content-center coin">
                                    <div><strong>{{ coin.coinName }}</strong></div>
                                    <div class="text-light-gray small">
                                        {{ coin.coinCode }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
export default {
    name: "Swap",
    data(){
        return{
            coins : [
                { coinID: 0, imageName: 'bitcoin.png', coinName: "Bitcoin", coinCode: 'BTC'},
                { coinID: 1, imageName: 'ethereum.png', coinName: "Ethereum", coinCode: 'ETH'},
                { coinID: 2, imageName: 'bitcoin.png', coinName: "Ripple", coinCode: 'XRP'},
                { coinID: 3, imageName: 'ethereum.png', coinName: "Carnado", coinCode: 'ADA'},
                { coinID: 4, imageName: 'bitcoin.png', coinName: "Binance Coin", coinCode: 'BNB'},
                { coinID: 5, imageName: 'ethereum.png', coinName: "Tether USD", coinCode: 'USTD'},
            ],
            selected: 0,
            swap_selected: 0,
            searchToken: ''
        };
    },
    methods: {
        showCurrentCoinModal() {
            this.$refs['currentCoinModal'].show()
        },
        showSwapCoinModal() {
            this.$refs['swapCoinModal'].show()
        },
        closeModal() {
            this.$refs['currentCoinModal'].hide()
            this.$refs['swapCoinModal'].hide()
            this.searchToken = ''
        }
    },
    computed: {
        coin_selected() {
            return this.coins.filter((coin) => coin.coinID === this.selected);
        },
        swap_coin_selected() {
            return this.coins.filter((coin) => coin.coinID === this.swap_selected);
        },
        filteredCurrentList() {
            return this.coins.filter(c => {
                return c.coinName.toLowerCase().includes(this.searchToken.toLowerCase())
            })
        },
        filteredSwapList() {
            return this.coins.filter(c => {
                return c.coinName.toLowerCase().includes(this.searchToken.toLowerCase())
            })
        }
    }
};
</script>

<style>
@import '../css/styles.css';

.swap-body {
    display: flex;
    flex-direction: column;
}

.swap-icon {
    width: 10%;
    display: flex;
    justify-content: center;
}

.select-coin-btn {
    border: none;
    background-color: transparent;
}

.select-coin-btn:hover {
    color:var(--primary);
}

.search-token-input {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    width: 100%;
}

.search-token-input input {
    border-radius: 20px;
    padding: 5px 50px;
    background-color: #505370;
    border: none;
    text-align: center;
    color: white;
    width: 100%;
}

.search-token-icon {
    position: absolute;
    left: 6%;
}

.token-list {
    width: 100%;
    background-color: transparent;
    height: 410px;
    overflow-y: scroll;
}

/* width */
.token-list::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.token-list::-webkit-scrollbar-track {
  background: #585858; 
}
 
/* Handle */
.token-list::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.token-list::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.search-token-div {
    padding: 15px;
    border-bottom: 1px solid #707070;
    cursor: pointer;
}

.search-token-div:hover {
    background-color: #414464;;
}

.swap-btn {
    background-color: #18324a;
    color:var(--primary);
    border-radius: 20px;
    border: 1px solid #18324a;
    width: 400px;
    margin-top: 30px;
    min-width: 200px;
    cursor: pointer;
    font-weight: bold;
    padding: 8px;
}

.swap-btn:hover {
    background-color: #1b1e36;
    border: 1px solid var(--primary);
    color: white;
}

.swap-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8%;
}

.swap-container-coin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45%;
}

.coin-img img {
    width: 80px;
}

.coin-dropdown, .coin-dropdown:focus {
    border-color: transparent;
    color: white;
    background-color: transparent;
    margin-top: 10px;
}

.coin-input {
    background-color: #2b2e44;
    border: none;
    border-radius: 15px;
    min-width: 150px;
    width: 250px;
    color: white;
    text-indent: 10px;
    margin-top: 20px;
}

.max-input {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.max-text {
    position: absolute;
    right: 5%;
    color: #707070;
    top: 44%;
}

.swap-coin-info {
    background-color: #32344a;
    margin-top: 20px;
    width: 400px;
    min-width: 200px;
    height: 100px;
    border-radius: 15px;
    color: #aeaeae;
    padding: 15px;
    align-items: center;
    font-size: 14px;
    height: 30%; 
    min-height: 30%;
}

.info-left-col {
    text-align: left;
    width: 50%;
} 

.info-right-col {
    text-align: right;
    width: 50%;
}

@media only screen and (max-width: 968px) and (max-width: 666px) and (max-width: 466px) {
    .swap-body {
        margin: 0% 8%;
    }
    
    .coin-img img {
        width: 50px;
    }

    .swap-container {
        flex-direction: column; 
    }

    .swap-icon {
        margin: 5% 0%;
    }

    .swap-icon img {
        width: 60px;
        transform: rotate(90deg);
        margin: 30%;
    }
}
</style>
